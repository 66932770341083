<template>
  <div></div>
</template>
<script>
export default {
  name: "Unauthorized",
  beforeCreate() {
    this.$oidc.signOut();
    localStorage.clear();
    sessionStorage.clear();
  },
};
</script>